// Generated by Framer (70e732d)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["kc5cpokSv"];

const variantClassNames = {kc5cpokSv: "framer-v-3nfjp3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "tween"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string; h?: any }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "kc5cpokSv", title: mQioQRAth = "Get Started", h: lq9YpSXEe, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "kc5cpokSv", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter1hdjulz = activeVariantCallback(async (...args) => {
if (lq9YpSXEe) {
const res = await lq9YpSXEe(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-BIKjD", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-3nfjp3", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"kc5cpokSv"} onMouseEnter={onMouseEnter1hdjulz} ref={ref} style={{backgroundColor: "rgb(0, 0, 0)", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} transition={transition}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNzAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "700", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Get Started</motion.p></React.Fragment>} className={"framer-17etj82"} fonts={["GF;Inter-700"]} layoutDependency={layoutDependency} layoutId={"oCUYVrI1R"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={mQioQRAth} transition={transition} verticalAlignment={"top"} withExternalLayout/><motion.div className={"framer-rjg6f8"} layoutDependency={layoutDependency} layoutId={"QN3mV1T9i"} style={{backgroundColor: "rgb(255, 255, 255)"}} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-BIKjD [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-BIKjD .framer-wdk22a { display: block; }", ".framer-BIKjD .framer-3nfjp3 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 52px; justify-content: center; overflow: hidden; padding: 15px 25px 15px 25px; position: relative; width: min-content; will-change: transform; }", ".framer-BIKjD .framer-17etj82 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-BIKjD .framer-rjg6f8 { bottom: 0px; flex: none; height: 55px; left: 0px; overflow: hidden; position: absolute; right: 0px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-BIKjD .framer-3nfjp3 { gap: 0px; } .framer-BIKjD .framer-3nfjp3 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-BIKjD .framer-3nfjp3 > :first-child { margin-left: 0px; } .framer-BIKjD .framer-3nfjp3 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 52
 * @framerIntrinsicWidth 129
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]}}}
 * @framerVariables {"mQioQRAth":"title","lq9YpSXEe":"h"}
 */
const FramerfbxlVncI9: React.ComponentType<Props> = withCSS(Component, css, "framer-BIKjD") as typeof Component;
export default FramerfbxlVncI9;

FramerfbxlVncI9.displayName = "Button";

FramerfbxlVncI9.defaultProps = {height: 52, width: 129};

addPropertyControls(FramerfbxlVncI9, {mQioQRAth: {defaultValue: "Get Started", displayTextArea: false, title: "Title", type: ControlType.String}, lq9YpSXEe: {title: "H", type: ControlType.EventHandler}} as any)

addFonts(FramerfbxlVncI9, [{family: "Inter", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/fbxlVncI9:default", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf", weight: "700"}])